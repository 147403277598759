import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Reducers';
import { Map } from 'immutable';
import * as serviceWorker from './serviceWorkerRegistration';
import { fromEventPattern } from 'rxjs';
import moment from 'moment-timezone';
import 'moment/locale/nl';
import { AuthenticationProvider } from './Authentication/Context/AuthenticationContext';
import { PWAProvider } from './PWA/Context/PWAContext';
import Authentication from './Authentication';
import ConnectionProvider from './Context/Connection';
import MissedEntriesProvider from './Context/MissedEntries';
moment.locale('nl', { dow: 1 });


export const appVersion = "1.1.18";

let composeMiddleware = undefined;

if(window.__REDUX_DEVTOOLS_EXTENSION__) {
    composeMiddleware = compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
} else {
    composeMiddleware = applyMiddleware(thunk);
}


const store = createStore(
    rootReducer, 
    Map(),
    composeMiddleware
);

ReactDOM.render(
    <ConnectionProvider>
        <PWAProvider>
            <AuthenticationProvider>
                <Authentication>
                    <MissedEntriesProvider>
                        <Provider store={store}>
                            <App />
                        </Provider>
                    </MissedEntriesProvider>
                </Authentication>
            </AuthenticationProvider>
        </PWAProvider>
    </ConnectionProvider>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
